.loader {
  aspect-ratio: 1;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#565FC4 94%,#0000) top/var(--loader-border-width) var(--loader-border-width) no-repeat,
    conic-gradient(#0000 30%,#565FC4);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - var(--loader-border-width)),#000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13{ 
  100%{transform: rotate(1turn)}
}
