@tailwind base;
@tailwind components;
@tailwind utilities;

body, #root {
  margin: 0;
  font-family: 'Poppins', Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #262626;
  background-color: rgb(252, 252, 252);
  letter-spacing: -0.025em;
}
