.advice-item {
    transform: scaleY(0);    
    transform-origin: top;
    transition: transform 0.3s ease-out;
}

.active-item__open {
    transform: scaleY(1);
}

.arrow-down__closed {
    transition-duration: 400ms;
    transform: rotateZ(-.25turn);
}
  
.arrow-down__open {
    transition-duration: 400ms;
}
