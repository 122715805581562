.tab-calendar {
    font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    border: thin solid #EDEBE9;
    box-shadow: 0px 16px 32px -12px #1F293726;
    border-radius: 12px;
}

.tab-calendar .react-datepicker__header {
    background-color: #FFFFFF;
    border-bottom: none;
    padding: 0;
}

.tab-calendar .react-datepicker__day--selected {
    color: #565FC4;
    font-weight: bold;
    background-color: #F2F4FC;
}

.tab-calendar .react-datepicker__day--selected:hover {
    color: #565FC4;
    font-weight: bold;
    background-color: #bfcaf6;
}

.tab-calendar .react-datepicker__day--keyboard-selected {
    background-color: #FAC34F;
}
